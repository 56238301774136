import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "./styles.css";

function Payment() {
  const { id } = useParams();
  const [depositRequest, setDepositRequest] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [webBank, setWebBank] = useState(null);
  const [depositStatus, setDepositStatus] = useState("PENDING");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchDepositRequest();
        await fetchWebBank();
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(() => {
      fetchDepositRequestloop();
    }, 10000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [id]);

  useEffect(() => {
    // If depositStatus changes to "UPDATE BY SMS", navigate to the game page
    if (depositStatus === "UPDATE BY SMS") {
      navigate("/game");
    }
  }, [depositStatus, navigate]);

  const fetchDepositRequestloop = async () => {
    const depositResponse = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/depositrequest/${id}`
    );
    setDepositStatus(depositResponse.data.status);
  };

  const fetchDepositRequest = async () => {
    const depositResponse = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/depositrequest/${id}`
    );
    setDepositRequest(depositResponse.data);

    const userResponse = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/user/${depositResponse.data.userId}`
    );
    setUser(userResponse.data);
  };

  const fetchWebBank = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/webbank`,
        { withCredentials: true }
      );
      console.log("Web Bank fetched:", response.data[0]);
      setWebBank(response.data[0]);
    } catch (error) {
      console.error("Error fetching web bank:", error);
    }
  };

  const handleCancelDeposit = async () => {
    console.log("Attempting to cancel deposit request with ID:", id);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/canceldeposit`,
        { depositRequestId: id },
        { withCredentials: true }
      );
      console.log("Cancel deposit response:", response.data);
      navigate("/game");
    } catch (err) {
      console.error(
        "Error canceling deposit request:",
        err.response ? err.response.data : err.message
      );
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!depositRequest || !user) return <p>No data available</p>;

  return (
    <div className="min-h-screen bg-login flex flex-col">
      <Header title="Realslot456" />
      <AccountDetails
        title="โอนจากบัญชี"
        accountNumber={user.bank?.accountNumber}
        accountName={user.bank?.accountName}
        bankCode={user.bank?.bankCode}
        bgColor="lime"
      />
      <ArrowDownIcon />
      <AccountDetails
        title="ไปยังบัญชี"
        accountNumber={webBank?.accountNumber}
        accountName={webBank?.accountName}
        bankCode={webBank?.bankCode}
        bgColor="yellow"
      />
      <AmountDetails amount={depositRequest?.amount} />
      <CancelButton onCancel={handleCancelDeposit} />
    </div>
  );
}

const Header = ({ title }) => (
  <header className="bg-pink-700 text-white py-4 shadow-lg">
    <div className="container mx-auto px-4">
      <h1 className="text-xl font-bold">{title}</h1>
    </div>
  </header>
);

const AccountDetails = ({
  title,
  accountNumber,
  accountName,
  bankCode,
  bgColor,
}) => (
  <div className="flex items-center justify-center opacity-90 mt-10">
    <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md flex flex-row">
      <div className="p-4">
        <img
          className="m-auto"
          src="https://via.placeholder.com/100"
          alt="logo"
        />
      </div>
      <div className="basis-3/4 m-auto pl-5">
        <span className={`bg-${bgColor}-600 rounded-full p-1`}>{title}</span>
        <br />
        <span>{accountNumber || "N/A"}</span>
        <br />
        <p className="text-gray-700">{accountName || "N/A"}</p>
        <p className="text-gray-700">Bank Code: {bankCode || "N/A"}</p>
      </div>
    </div>
  </div>
);

const ArrowDownIcon = () => (
  <div className="item-center flex justify-center mt-10">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
      id="arrow-down"
    >
      <path
        fill="url(#paint0_linear_61_3079)"
        d="M31.9991 59.3207C46.9188 59.3207 59.3207 46.9456 59.3207 31.9992C59.3207 17.0796 46.892 4.67773 31.9724 4.67773C17.0258 4.67773 4.67773 17.0796 4.67773 31.9992C4.67773 46.9456 17.0527 59.3207 31.9991 59.3207ZM41.5348 24.4725C42.8473 24.4725 43.4366 25.9189 42.6865 27.2046L33.4724 42.8207C32.8026 43.9725 31.1687 43.9189 30.526 42.8207L21.2848 27.2046C20.5349 25.9992 21.0974 24.4725 22.4366 24.4725H41.5348Z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_61_3079"
          x1="31.999"
          x2="31.999"
          y1="4.678"
          y2="59.321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#466AFF"></stop>
          <stop offset="1" stopColor="#06B8FF"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
);

const AmountDetails = ({ amount }) => (
  <div className="flex items-center justify-center opacity-90 mt-10">
    <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md flex flex-row justify-center">
      <h1>
        จำนวน {amount?.$numberDecimal ? `${amount.$numberDecimal}` : "N/A"} บาท
      </h1>
    </div>
  </div>
);

const CancelButton = ({ onCancel }) => (
  <div className="flex items-center justify-center opacity-90 mt-3">
    <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md flex flex-row justify-center">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
        onClick={onCancel}
      >
        ยกเลิกการเติมเงิน
      </button>
    </div>
  </div>
);

export default Payment;
