import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import videoBackground from "../vdo.mp4";
import "./styles.css";
import hero from "../hero.png";

const LoginRegister = () => {
  const navigate = useNavigate();
  const [isRegister, setIsRegister] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Reference for the video
  const videoRef = useRef(null);

  useEffect(() => {
    const enableAutoplayOnUserGesture = () => {
      if (videoRef.current) {
        videoRef.current.muted = true; // Ensure the video is muted
        videoRef.current.play().catch((error) => {
          console.log("Autoplay prevented. Playing on user gesture:", error);
        });
      }
    };

    // Attach a touchstart event for iOS devices to trigger play
    document.addEventListener("touchstart", enableAutoplayOnUserGesture, {
      once: true,
    });

    // Clean up the event listener after first interaction
    return () => {
      document.removeEventListener("touchstart", enableAutoplayOnUserGesture);
    };
  }, []);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/api/auto-login",
          {
            method: "GET",
            credentials: "include", // Include cookies in the request
          }
        );

        if (response.ok) {
          // If the user is authenticated, redirect to the game page
          navigate("/game");
        }
        // If not authenticated, do nothing (let the user see the login page)
      } catch (error) {
        console.error("Failed to check login status:", error);
      }
    };

    checkLoginStatus();
  }, [navigate]);

  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVh(); // Set the initial value

    window.addEventListener("resize", setVh); // Adjust when the window is resized

    return () => window.removeEventListener("resize", setVh); // Clean up the event listener
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ username, password }),
        }
      );

      if (response.ok) {
        navigate("/game");
      } else {
        const data = await response.json();
        setErrorMessage(data.message || "Login failed");
      }
    } catch (error) {
      setErrorMessage("Login failed");
      console.error("Login error:", error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            password,
            bankName,
            accountNumber,
            accountName,
          }),
        }
      );

      if (response.ok) {
        navigate("/game");
      } else {
        const data = await response.json();
        setErrorMessage(data.message || "Registration failed");
      }
    } catch (error) {
      setErrorMessage("Registration failed");
      console.error("Registration error:", error);
    }
  };

  const handleToggle = () => {
    setIsRegister(!isRegister);
    setErrorMessage("");
  };

  return (
    <div className="min-h-screen flex flex-col relative">
      {/* Video Background */}
      <video
        ref={videoRef} // Add the ref here
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Content */}
      <header className="relative z-10 bg-pink-700 text-white py-4 shadow-lg">
        <div className="container mx-auto px-4">
          <h1 className="text-xl font-bold text-center">REALSLOT456</h1>
        </div>
      </header>

      {/* Centered Content */}
      <div className="flex-center relative z-10">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-sm bg-with-opacity">
          {isRegister ? (
            <>
              <h1 className="text-2xl font-bold mb-6 text-center">
                สมัครสมาชิก
              </h1>
              <form onSubmit={handleRegister}>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mb-4 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mb-4 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                <input
                  type="text"
                  placeholder="Bank Name"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  className="mb-4 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                <input
                  type="text"
                  placeholder="Account Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  className="mb-4 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                <input
                  type="text"
                  placeholder="Account Name"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                  className="mb-6 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                {errorMessage && (
                  <p className="text-red-500 text-center mb-4">
                    {errorMessage}
                  </p>
                )}
                <button
                  type="submit"
                  className="w-full bg-green-500 text-white py-4 px-4 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 input-no-opacity"
                >
                  Register
                </button>
              </form>
              <p className="mt-4 text-center">
                Already have an account?{" "}
                <button
                  onClick={handleToggle}
                  className="text-blue-500 hover:underline focus:outline-none"
                >
                  Login here
                </button>
              </p>
            </>
          ) : (
            <>
              <img src={hero} alt="hero" className="w-20 mx-auto mb-5" />
              {/* <form onSubmit={handleLogin}>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mb-4 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mb-6 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                {errorMessage && (
                  <p className="text-red-500 text-center mb-4">
                    {errorMessage}
                  </p>
                )}
                <button
                  type="submit"
                  className="mt-0 w-full bg-blue-500 text-white py-4 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  เช้าสู่ระบบ
                </button>
              </form> */}
              {/* <p className="mt-4 text-center">หรือ</p> */}
              <button
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_API_URL + "/api/login")
                }
                className="w-full bg-green-500 text-white py-4 px-4 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                สมัครและรับ promotion x 2
              </button>
              {/* <p className="mt-4 text-center">
                ยังไม่เป็นสมาชิกใช่ไหม{" "}
                <button
                  onClick={handleToggle}
                  className="text-blue-500 hover:underline focus:outline-none"
                >
                  สมัครสมาชิก
                </button>
              </p> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginRegister;
